import { GameStatusEnum } from "@enums/GameStatusEnum";

import { GameDetailModel } from "./Game";

export class RoundItemDetailsModel {
  round: GameDetailModel
  isSingleEventRound: boolean
  isDataProcessingDisallow: boolean
  isHistoryDisallow: boolean
  gameButtonText: string
  gameButtonColor: string
  gameButtonTextColor: string
  gameStatus: GameStatusEnum
  nextRoundOpensAt?: string;
  name?: string
  prizeLabel: string
  gameButtonBorderColor?: string
  labelTextContent?: string
  labelIconContent?: string
  bonusText?: string
  prizeText?: string
  id?: string
}

export class RoundSetDetailsModel {
  [key: number]: RoundItemDetailsModel
}
