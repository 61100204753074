import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { CMSContentConfigModel } from "@models/CMSContentConfigModel";
import { LocalStorageService } from "@services/local-storage.service";
import { SnackBarService } from "@services/snack-bar.service";
import { UriConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class CmsContentService {

  cmsContent$: BehaviorSubject<CMSContentConfigModel> = new BehaviorSubject(new CMSContentConfigModel);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private localStorageService: LocalStorageService,
    private snackBarService: SnackBarService,
  ) {}

  getCmsContent(locale?: string) {
    return this.http.get(`${this.uriConfig.cms}/content`);
  }

  loadCmsContent(hashResponse: string, lang?: string): Observable<any> {
    const hash = this.localStorageService.getCmsContentHash();
    const savedContent = this.localStorageService.getCmsContent();
    const prevLocale = this.localStorageService.getLocale();
    if (savedContent && hash === hashResponse && lang === prevLocale) {
      this.cmsContent$.next(savedContent);
      this.checkCocpitCollection(savedContent);
      this.setUpColors(savedContent);
      this.setUpImage(savedContent);
      return of(savedContent);
    } else {
      return this.getCmsContent(lang).pipe(
        switchMap((content: any) => {
          this.localStorageService.setCmsContentHash(hashResponse);
          this.checkCocpitCollection(content);
          const preparedContent = this.convertCmsContent(content);
          this.localStorageService.setCmsContent(preparedContent);
          this.cmsContent$.next(preparedContent);
          this.setUpColors(preparedContent);
          this.setUpImage(preparedContent);
          return of(content);
        }),
        catchError(error => {
          const defaultConfig = {
            ['image_content']: {},
            ['text_content']: {}
          }
          this.cmsContent$.next(defaultConfig as CMSContentConfigModel);
          return throwError(error);
        })
      );
    }
  }

  getCMSContentLabel(label: string): string {
    return this.cmsContent$.value['text_content'][label]
  }

  getCMSContentColor(): { [p: string]: string } {
    return this.cmsContent$.value['colour_content']
  }

  convertCmsContent(content): CMSContentConfigModel {
    const config = {};
    Object.keys(content).forEach(key => {
      config[key] = content[key].reduce((acc, item) => {
        acc[item.Name] = item['Colour'] || item['image_content'] || item['seo_content'] || item['Text value']
        return acc;
      }, {});
    });
    this.checkConfigFields(config);
    return config as CMSContentConfigModel;
  }

  checkConfigFields(config) {
    if (!config['image_content']) config['image_content'] = {};
    if (!config['text_content']) config['text_content'] = {};
  }

  checkCocpitCollection(content) {
    if (this.isConfigFileEmpty(content)) {
      return this.snackBarService.showSnackBar('There is no content');
    }
    if (!content['image_content'] || !Object.keys(content['image_content']).length) {
      return this.snackBarService.showSnackBar('There is no image content');
    }
    if (!content['text_content'] || !Object.keys(content['text_content']).length) {
      return this.snackBarService.showSnackBar('There is no text content');
    }
    if (!content['colour_content'] || !Object.keys(content['colour_content']).length) {
      return this.snackBarService.showSnackBar('There is no colour content');
    }
  }

  isConfigFileEmpty(content) {
    if (!content || !Object.keys(content).length) return true;
    return Object.keys(content).length === 2 &&!Object.keys(content.image_content).length && !Object.keys(content.text_content).length;
  }

  setUpColors(content) {
    if (!content || !content['colour_content'] || !Object.keys(content['colour_content'] ).length) return;
    document.documentElement.style.setProperty('--primary-color-200', content['colour_content']['primary-200'])
    document.documentElement.style.setProperty('--light-color', content['colour_content']['light-color']);
    document.documentElement.style.setProperty('--dark-color-200', content['colour_content']['dark-color-200']);
    document.documentElement.style.setProperty('--iframe-prompt-light', content['colour_content']['iframe-prompt-light']);
    document.documentElement.style.setProperty('--iframev2-yellow', content['colour_content']['iframev2-yellow']);
    document.documentElement.style.setProperty('--iframev2-light-color', content['colour_content']['iframev2-light-color']);
    document.documentElement.style.setProperty('--iframev2-dark-100', content['colour_content']['iframev2-dark-100']);
    document.documentElement.style.setProperty('--napoleon-add-name-modal-bg', content['colour_content']['napoleon-add-name-modal-bg']);
    document.documentElement.style.setProperty('--iframev2-brazil-menu-bg-color', content['colour_content']['iframev2-brazil-menu-bg-color']);
    // iframe-v3
    document.documentElement.style.setProperty('--iframe-v3-info-popup-expansion-panel-bottom-color', content['colour_content']['iframe-v3-info-popup-expansion-panel-bottom-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-popup-expansion-panel-body-color', content['colour_content']['iframe-v3-info-popup-expansion-panel-body-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-popup-expansion-panel-body-background', content['colour_content']['iframe-v3-info-popup-expansion-panel-body-background']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-filter-dark-100', content['colour_content']['iframe-v3-info-modal-filter-dark-100']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-mobile-menu-item-color', content['colour_content']['iframe-v3-info-modal-mobile-menu-item-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-light-color', content['colour_content']['iframe-v3-info-modal-light-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-light-color-opacity-60', content['colour_content']['iframe-v3-info-modal-light-color-opacity-60']);
    document.documentElement.style.setProperty('--iframe-v3-overlay-panel-color', content['colour_content']['iframe-v3-overlay-panel-color']);
    document.documentElement.style.setProperty('--iframe-v3-overlay-panel-background', content['colour_content']['iframe-v3-overlay-panel-background']);
    document.documentElement.style.setProperty('--iframe-v3-main-modal-dark-100', content['colour_content']['iframe-v3-main-modal-dark-100']);
    document.documentElement.style.setProperty('--iframe-v3-landing-title-text-color', content['colour_content']['iframe-v3-landing-title-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-button-text-color', content['colour_content']['iframe-v3-info-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-prize-block-text-color', content['colour_content']['iframe-v3-landing-prize-block-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-menu-background', content['colour_content']['iframe-v3-menu-background']);
    document.documentElement.style.setProperty('--iframe-v3-menu-regular-text-color', content['colour_content']['iframe-v3-menu-regular-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-menu-item-active-icon-filter', content['colour_content']['iframe-v3-menu-item-active-icon-filter']);
    document.documentElement.style.setProperty('--iframe-v3-menu-active-text-color', content['colour_content']['iframe-v3-menu-active-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-menu-active-icon-filter', content['colour_content']['iframe-v3-menu-active-icon-filter']);
    document.documentElement.style.setProperty('--iframe-v3-preview-line-dark-color-200', content['colour_content']['iframe-v3-preview-line-dark-color-200']);


    document.documentElement.style.setProperty('--iframe-v3-base-button-text-color', content['colour_content']['iframe-v3-base-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-none-color', content['colour_content']['iframe-v3-none-color']);
    document.documentElement.style.setProperty('--iframe-v3-base-button-color', content['colour_content']['iframe-v3-base-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-timer-numbers-color', content['colour_content']['iframe-v3-timer-numbers-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-border', content['colour_content']['iframe-v3-landing-round-border']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-background', content['colour_content']['iframe-v3-landing-round-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-background-light-gradient', content['colour_content']['iframe-v3-landing-round-background-light-gradient']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-bottom-container-background', content['colour_content']['iframe-v3-landing-round-bottom-container-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-title-color', content['colour_content']['iframe-v3-landing-round-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-label-color', content['colour_content']['iframe-v3-landing-round-label-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-label-background', content['colour_content']['iframe-v3-landing-round-label-background']);
    document.documentElement.style.setProperty('--iframe-v3-round-header-timer-color', content['colour_content']['iframe-v3-round-header-timer-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-timer-explanation', content['colour_content']['iframe-v3-round-timer-explanation']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-loss-first-gradient-color', content['colour_content']['iframe-v3-round-label-loss-first-gradient-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-loss-second-gradient-color', content['colour_content']['iframe-v3-round-label-loss-second-gradient-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-winn-second-gradient-color', content['colour_content']['iframe-v3-round-label-winn-second-gradient-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-winn-first-gradient-color', content['colour_content']['iframe-v3-round-label-winn-first-gradient-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-winn-text-color', content['colour_content']['iframe-v3-round-label-winn-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-loss-text-color', content['colour_content']['iframe-v3-round-label-loss-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-prize-bottom-container-background', content['colour_content']['iframe-v3-landing-round-prize-bottom-container-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-prize-container-background', content['colour_content']['iframe-v3-landing-round-prize-container-background']);
    document.documentElement.style.setProperty('--iframe-v3-round-header-multy-event-round-name-color', content['colour_content']['iframe-v3-round-header-multy-event-round-name-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-prize-info-color', content['colour_content']['iframe-v3-round-prize-info-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-prize-info-border-color', content['colour_content']['iframe-v3-round-prize-info-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-prize-shared-description-color', content['colour_content']['iframe-v3-round-prize-shared-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-question-card-color', content['colour_content']['iframe-v3-my-predictions-question-card-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-question-logo-border-color', content['colour_content']['iframe-v3-my-predictions-question-logo-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-question-logo-background-color', content['colour_content']['iframe-v3-my-predictions-question-logo-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-light-color-opacity-60', content['colour_content']['iframe-v3-my-predictions-light-color-opacity-60']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-question-title-color', content['colour_content']['iframe-v3-my-predictions-question-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-question-footer-background', content['colour_content']['iframe-v3-my-predictions-question-footer-background']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-submit-button-color', content['colour_content']['iframe-v3-my-predictions-submit-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-list-question-answer-background-color', content['colour_content']['iframe-v3-list-question-answer-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-list-question-answer-color', content['colour_content']['iframe-v3-list-question-answer-color']);
    document.documentElement.style.setProperty('--iframe-v3-list-question-answer-selected-background-color', content['colour_content']['iframe-v3-list-question-answer-selected-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-list-question-answer-selected-color', content['colour_content']['iframe-v3-list-question-answer-selected-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-carousel-indicator-unactive', content['colour_content']['iframe-v3-landing-carousel-indicator-unactive']);
    document.documentElement.style.setProperty('--iframe-v3-landing-carousel-indicator-active', content['colour_content']['iframe-v3-landing-carousel-indicator-active']);
    document.documentElement.style.setProperty('--iframe-v3-range-slider-active-color', content['colour_content']['iframe-v3-range-slider-active-color']);
    document.documentElement.style.setProperty('--iframe-v3-range-slider-value-background', content['colour_content']['iframe-v3-range-slider-value-background']);
    document.documentElement.style.setProperty('--iframe-v3-range-slider-unactive-color', content['colour_content']['iframe-v3-range-slider-unactive-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-selection-modal-ok-button-color',  content['colour_content']['iframe-v3-confirm-selection-modal-ok-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-selection-modal-cancel-button-color',  content['colour_content']['iframe-v3-confirm-selection-modal-cancel-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-button-background-color',  content['colour_content']['iframe-v3-confirm-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-button-border-color',  content['colour_content']['iframe-v3-confirm-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-cancel-button-background-color',  content['colour_content']['iframe-v3-cancel-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-cancel-button-border-color',  content['colour_content']['iframe-v3-cancel-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-exit-modal-ok-button-color',  content['colour_content']['iframe-v3-confirm-exit-modal-ok-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-exit-modal-cancel-button-color',  content['colour_content']['iframe-v3-confirm-exit-modal-cancel-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-exit-button-background-color',  content['colour_content']['iframe-v3-confirm-exit-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-exit-button-border-color',  content['colour_content']['iframe-v3-confirm-exit-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-cancel-exit-button-background-color', content['colour_content']['iframe-v3-cancel-exit-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-cancel-exit-button-border-color',  content['colour_content']['iframe-v3-cancel-exit-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-banned-user-modal-cancel-button-color',  content['colour_content']['iframe-v3-banned-user-modal-cancel-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-base-modal-title-color',  content['colour_content']['iframe-v3-base-modal-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-base-modal-description-color',  content['colour_content']['iframe-v3-base-modal-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-base-modal-additional-content-color',  content['colour_content']['iframe-v3-base-modal-additional-content-color']);
    document.documentElement.style.setProperty('--iframe-v3-confirm-popup-background-color',  content['colour_content']['iframe-v3-confirm-popup-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-singin-modal-ok-button-color',  content['colour_content']['iframe-v3-singin-modal-ok-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-singin-modal-cancel-button-color',  content['colour_content']['iframe-v3-singin-modal-cancel-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-singin-button-background-color',  content['colour_content']['iframe-v3-singin-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-singin-button-border-color',  content['colour_content']['iframe-v3-singin-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-singin-cancel-button-background-color',  content['colour_content']['iframe-v3-singin-cancel-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-singin-cancel-button-border-color',  content['colour_content']['iframe-v3-singin-cancel-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-divider-color', content['colour_content']['iframe-v3-divider-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-my-picks-block-background', content['colour_content']['iframe-v3-my-picks-my-picks-block-background']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-my-picks-block-border', content['colour_content']['iframe-v3-my-picks-my-picks-block-border']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-my-picks-block-text-color', content['colour_content']['iframe-v3-my-picks-my-picks-block-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-question-number-color', content['colour_content']['iframe-v3-my-picks-question-number-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-question-text-color', content['colour_content']['iframe-v3-my-picks-question-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-general-container-border', content['colour_content']['iframe-v3-my-picks-general-container-border']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-general-container-background', content['colour_content']['iframe-v3-my-picks-general-container-background']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-general-container-divider-gradient-end', content['colour_content']['iframe-v3-my-picks-general-container-divider-gradient-end']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-right-answer-border', content['colour_content']['iframe-v3-my-picks-right-answer-border']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-right-answer-background', content['colour_content']['iframe-v3-my-picks-right-answer-background']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-answer-image-shadow', content['colour_content']['iframe-v3-my-picks-answer-image-shadow']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-wrong-answer-border', content['colour_content']['iframe-v3-my-picks-wrong-answer-border']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-wrong-answer-backgroundr-border', content['colour_content']['iframe-v3-my-picks-wrong-answer-background']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-pending-answer-border', content['colour_content']['iframe-v3-my-picks-pending-answer-border']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-pending-answer-backround', content['colour_content']['iframe-v3-my-picks-pending-answer-backround']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-answer-background', content['colour_content']['iframe-v3-my-picks-answer-background']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-answer-color', content['colour_content']['iframe-v3-my-picks-answer-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-block-title-color', content['colour_content']['iframe-v3-my-picks-block-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-prize-button-text-color', content['colour_content']['iframe-v3-prize-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-prize-button-border-color', content['colour_content']['iframe-v3-prize-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-edit-button-border-color', content['colour_content']['iframe-v3-my-picks-edit-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-edit-button-text-color', content['colour_content']['iframe-v3-my-picks-edit-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-edit-button-background-color', content['colour_content']['iframe-v3-my-picks-edit-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-prize-button-color', content['colour_content']['iframe-v3-prize-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-play-game-button-background', content['colour_content']['iframe-v3-landing-play-game-button-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-edit-game-button-background', content['colour_content']['iframe-v3-landing-edit-game-button-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-closed-game-button-background', content['colour_content']['iframe-v3-landing-closed-game-button-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-play-game-button-color', content['colour_content']['iframe-v3-landing-play-game-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-edit-game-button-color', content['colour_content']['iframe-v3-landing-edit-game-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-closed-game-button-color', content['colour_content']['iframe-v3-landing-closed-game-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-my-picks-block-timer-color', content['colour_content']['iframe-v3-my-picks-my-picks-block-timer-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-my-picks-block-second-line-color', content['colour_content']['iframe-v3-my-picks-my-picks-block-second-line-color']);
    document.documentElement.style.setProperty('--iframe-v3-score-question-team-name-color', content['colour_content']['iframe-v3-score-question-team-name-color']);
    document.documentElement.style.setProperty('--iframe-v3-score-question-conter-background', content['colour_content']['iframe-v3-score-question-conter-background']);
    document.documentElement.style.setProperty('--iframe-v3-score-question-conter-controll-button-color', content['colour_content']['iframe-v3-score-question-conter-controll-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-score-question-conter-value-color', content['colour_content']['iframe-v3-score-question-conter-value-color']);
    document.documentElement.style.setProperty('--iframe-v3-score-question-controller-background', content['colour_content']['iframe-v3-score-question-controller-background']);
    document.documentElement.style.setProperty('--iframe-v3-round-prize-info-background-color', content['colour_content']['iframe-v3-round-prize-info-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-see-results-button-color', content['colour_content']['iframe-v3-see-results-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-see-results-button-background-color', content['colour_content']['iframe-v3-see-results-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-results-round-wrapper-background', content['colour_content']['iframe-v3-results-round-wrapper-background']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-loss-color', content['colour_content']['iframe-v3-round-label-loss-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-label-winn-color', content['colour_content']['iframe-v3-round-label-winn-color']);
    document.documentElement.style.setProperty('--iframe-v3-results-info-massage-background', content['colour_content']['iframe-v3-results-info-massage-background']);
    document.documentElement.style.setProperty('--iframe-v3-results-info-massage-title-color', content['colour_content']['iframe-v3-results-info-massage-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-results-info-massage-description-color', content['colour_content']['iframe-v3-results-info-massage-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-results-info-massage-winn-label-background', content['colour_content']['iframe-v3-results-info-massage-winn-label-background']);
    document.documentElement.style.setProperty('--iframe-v3-results-info-massage-winn-label-color', content['colour_content']['iframe-v3-results-info-massage-winn-label-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-info-wrapper-score-color', content['colour_content']['iframe-v3-round-info-wrapper-score-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-info-date-color', content['colour_content']['iframe-v3-round-info-date-color']);
    document.documentElement.style.setProperty('--iframe-v3-light-200-color', content['colour_content']['iframe-v3-light-200-color']);
    document.documentElement.style.setProperty('--iframe-v3-result-time-color', content['colour_content']['iframe-v3-result-time-color']);
    document.documentElement.style.setProperty('--iframe-v3-dark-600-background', content['colour_content']['iframe-v3-dark-600-background']);
    document.documentElement.style.setProperty('--iframe-v3-result-tab-color', content['colour_content']['iframe-v3-result-tab-color']);
    document.documentElement.style.setProperty('--iframe-v3-result-tab-color-active', content['colour_content']['iframe-v3-result-tab-color-active']);
    document.documentElement.style.setProperty('--iframe-v3-result-tab-global-border-color', content['colour_content']['iframe-v3-result-tab-global-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-winning-card-background', content['colour_content']['iframe-v3-winning-card-background']);
    document.documentElement.style.setProperty('--iframe-v3-winning-card-text-color', content['colour_content']['iframe-v3-winning-card-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-winning-card-text-lighter-color', content['colour_content']['iframe-v3-winning-card-text-lighter-color']);
    document.documentElement.style.setProperty('--iframe-v3-winning-card-title-color', content['colour_content']['iframe-v3-winning-card-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-score-question-controller-background-disabled', content['colour_content']['iframe-v3-score-question-controller-background-disabled']);
    document.documentElement.style.setProperty('--iframe-v3-score-question-conter-controll-button-color-disabled', content['colour_content']['iframe-v3-score-question-conter-controll-button-color-disabled']);
    document.documentElement.style.setProperty('--iframe-v3-lock-page-background', content['colour_content']['iframe-v3-lock-page-background']);
    document.documentElement.style.setProperty('--iframe-v3-lock-page-text-color', content['colour_content']['iframe-v3-lock-page-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-page-title', content['colour_content']['iframe-v3-leaderboard-page-title']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-filter-item', content['colour_content']['iframe-v3-leaderboard-filter-item']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-filter-item-selected', content['colour_content']['iframe-v3-leaderboard-filter-item-selected']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-list-background', content['colour_content']['iframe-v3-leaderboard-list-background']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-player-color-text', content['colour_content']['iframe-v3-leaderboard-player-color-text']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-player-border-color', content['colour_content']['iframe-v3-leaderboard-player-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-current-user-background', content['colour_content']['iframe-v3-leaderboard-current-user-background']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-current-user-position-background', content['colour_content']['iframe-v3-leaderboard-current-user-position-background']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-current-user-position-text-color', content['colour_content']['iframe-v3-leaderboard-current-user-position-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-current-user-text-color', content['colour_content']['iframe-v3-leaderboard-current-user-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-empty-text', content['colour_content']['iframe-v3-leaderboard-empty-text']);
    document.documentElement.style.setProperty('--iframe-v3-mobile-menu-item-color', content['colour_content']['iframe-v3-mobile-menu-item-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-button-background-color', content['colour_content']['iframe-v3-info-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-menu-arrow-color', content['colour_content']['iframe-v3-menu-arrow-color']);
    document.documentElement.style.setProperty('--iframe-v3-background-gradient', content['colour_content']['iframe-v3-background-gradient']);
    document.documentElement.style.setProperty('--iframe-v3-overlay-panel-header-color', content['colour_content']['iframe-v3-overlay-panel-header-color']);
    document.documentElement.style.setProperty('--iframe-v3-overlay-panel-filter-color', content['colour_content']['iframe-v3-overlay-panel-filter-color']);
    document.documentElement.style.setProperty('--iframe-v3-overlay-panel-body-header-color', content['colour_content']['iframe-v3-overlay-panel-body-header-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-popup-expansion-panel-title', content['colour_content']['iframe-v3-info-popup-expansion-panel-title']);
    document.documentElement.style.setProperty('--iframe-v3-info-popup-expansion-panel-arrow', content['colour_content']['iframe-v3-info-popup-expansion-panel-arrow']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-filter-slider-border-color', content['colour_content']['iframe-v3-info-modal-filter-slider-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-filter-slider-background-color', content['colour_content']['iframe-v3-info-modal-filter-slider-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-filter-slider-gradient-left', content['colour_content']['iframe-v3-info-modal-filter-slider-gradient-left']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-filter-slider-gradient-right', content['colour_content']['iframe-v3-info-modal-filter-slider-gradient-right']);
    document.documentElement.style.setProperty('--iframe-v3-banned-user-cancel-button-border-color', content['colour_content']['iframe-v3-banned-user-cancel-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-light-color', content['colour_content']['iframe-v3-light-color']);
    document.documentElement.style.setProperty('--iframe-v3-input-placeholder-color', content['colour_content']['iframe-v3-input-placeholder-color']);
    document.documentElement.style.setProperty('--iframe-v3-input-error', content['colour_content']['iframe-v3-input-error']);
    document.documentElement.style.setProperty('--iframe-v3-input-background', content['colour_content']['iframe-v3-input-background']);
    document.documentElement.style.setProperty('--iframe-v3-input-border-color', content['colour_content']['iframe-v3-input-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-add-name-modal-confirm-button-color', content['colour_content']['iframe-v3-add-name-modal-confirm-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-add-name-modal-cancel-button-color', content['colour_content']['iframe-v3-add-name-modal-cancel-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-add-name-modal-confirm-background-color', content['colour_content']['iframe-v3-add-name-modal-confirm-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-add-name-modal-cancel-background-color', content['colour_content']['iframe-v3-add-name-modal-cancel-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-add-name-modal-cancel-button-border-color', content['colour_content']['iframe-v3-add-name-modal-cancel-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-round-info-team-name-color', content['colour_content']['iframe-v3-round-info-team-name-color']);
    document.documentElement.style.setProperty('--iframe-v3-terms-color', content['colour_content']['iframe-v3-terms-color']);
    document.documentElement.style.setProperty('--iframe-v3-add-name-error', content['colour_content']['iframe-v3-add-name-error']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-filter-border-color', content['colour_content']['iframe-v3-info-modal-filter-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-add-title-color', content['colour_content']['iframe-v3-my-picks-add-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-all-results-prize-code-color', content['colour_content']['iframe-v3-all-results-prize-code-color']);
    document.documentElement.style.setProperty('--iframe-v3-all-results-correct-answer', content['colour_content']['iframe-v3-all-results-correct-answer']);
    document.documentElement.style.setProperty('--iframe-v3-team-color-box-shadow', content['colour_content']['iframe-v3-team-color-box-shadow']);
    document.documentElement.style.setProperty('--iframe-v3-round-header-timer-color-with-opacity', content['colour_content']['iframe-v3-round-header-timer-color-with-opacity']);
    document.documentElement.style.setProperty('--iframe-v3-landing-round-title-color-with-opacity', content['colour_content']['iframe-v3-landing-round-title-color-with-opacity']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-cross-color-filter', content['colour_content']['iframe-v3-my-predictions-cross-color-filter']);
    document.documentElement.style.setProperty('--iframe-v3-menu-nav-item-background', content['colour_content']['iframe-v3-menu-nav-item-background']);
    document.documentElement.style.setProperty('--iframe-v3-menu-nav-item-active-background', content['colour_content']['iframe-v3-menu-nav-item-active-background']);
    document.documentElement.style.setProperty('--iframe-v3-menu-nav-item-text-active-color', content['colour_content']['iframe-v3-menu-nav-item-text-active-color']);
    document.documentElement.style.setProperty('--iframe-v3-menu-nav-item-image-filter', content['colour_content']['iframe-v3-menu-nav-item-image-filter']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-event-card-background', content['colour_content']['iframe-v3-my-predictions-event-card-background']);
    document.documentElement.style.setProperty('--iframe-v3-custom-leaderboard-description-border-color', content['colour_content']['iframe-v3-custom-leaderboard-description-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-custom-leaderboard-description-text-color', content['colour_content']['iframe-v3-custom-leaderboard-description-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-menu-background-mobile', content['colour_content']['iframe-v3-menu-background-mobile']);
    document.documentElement.style.setProperty('--iframe-v3-menu-nav-item-mobile-background-active', content['colour_content']['iframe-v3-menu-nav-item-mobile-background-active']);
    document.documentElement.style.setProperty('--iframe-v3-menu-nav-item-text-active-color-mobile', content['colour_content']['iframe-v3-menu-nav-item-text-active-color-mobile']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-info-block-description-color', content['colour_content']['iframe-v3-my-picks-info-block-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-upsell-banner-title', content['colour_content']['iframe-v3-upsell-banner-title']);
    document.documentElement.style.setProperty('--iframe-v3-upsell-banner-background', content['colour_content']['iframe-v3-upsell-banner-background']);
    document.documentElement.style.setProperty('--iframe-v3-upsell-banner-button-background', content['colour_content']['iframe-v3-upsell-banner-button-background']);
    document.documentElement.style.setProperty('--iframe-v3-upsell-banner-button-text-color', content['colour_content']['iframe-v3-upsell-banner-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-upsell-banner-main-dark-color', content['colour_content']['iframe-v3-upsell-banner-main-dark-color']);
    document.documentElement.style.setProperty('--iframe-v3-upsell-banner-main-light-color', content['colour_content']['iframe-v3-upsell-banner-main-light-color']);
    document.documentElement.style.setProperty('--iframe-v3-upsell-banner-right-block-background', content['colour_content']['iframe-v3-upsell-banner-right-block-background']);

    document.documentElement.style.setProperty('--iframe-v3-my-prediction-back-button-color', content['colour_content']['iframe-v3-my-prediction-back-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-back-button-text-color', content['colour_content']['iframe-v3-my-predictions-back-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-predictions-locked-text-color', content['colour_content']['iframe-v3-my-predictions-locked-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-next-button-background-color', content['colour_content']['iframe-v3-next-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-next-button-text-color', content['colour_content']['iframe-v3-next-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-next-button-disabled-background', content['colour_content']['iframe-v3-next-button-disabled-background']);
    document.documentElement.style.setProperty('--iframe-v3-submit-button-disabled-background', content['colour_content']['iframe-v3-submit-button-disabled-background']);
    document.documentElement.style.setProperty('--iframe-v3-submit-button-disabled-text-color', content['colour_content']['iframe-v3-submit-button-disabled-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-next-button-disabled-text-color', content['colour_content']['iframe-v3-next-button-disabled-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-prize-modal-title-color', content['colour_content']['iframe-v3-prize-modal-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-prize-modal-button-text-color', content['colour_content']['iframe-v3-prize-modal-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-prize-modal-button-color', content['colour_content']['iframe-v3-prize-modal-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-info-prize-label-background', content['colour_content']['iframe-v3-landing-info-prize-label-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-info-prize-label-text-color', content['colour_content']['iframe-v3-landing-info-prize-label-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-range-slider-range-color', content['colour_content']['iframe-v3-range-slider-range-color']);
    document.documentElement.style.setProperty('--iframe-v3-range-slider-value-color', content['colour_content']['iframe-v3-range-slider-value-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-label-color', content['colour_content']['iframe-v3-special-leaderboard-label-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-info-header-color', content['colour_content']['iframe-v3-special-leaderboard-info-header-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-info-prize-color', content['colour_content']['iframe-v3-special-leaderboard-info-prize-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-info-prize-description-color', content['colour_content']['iframe-v3-special-leaderboard-info-prize-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-user-info-main-color', content['colour_content']['iframe-v3-special-leaderboard-user-info-main-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-user-info-background-color', content['colour_content']['iframe-v3-special-leaderboard-user-info-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-user-position-background-color', content['colour_content']['iframe-v3-special-leaderboard-user-position-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-user-position-color', content['colour_content']['iframe-v3-special-leaderboard-user-position-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-user-tier-explain-text-color', content['colour_content']['iframe-v3-special-leaderboard-user-tier-explain-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-user-points-amount-color', content['colour_content']['iframe-v3-special-leaderboard-user-points-amount-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-user-hover-color', content['colour_content']['iframe-v3-special-leaderboard-user-hover-color']);
    document.documentElement.style.setProperty('--iframe-v3-special-leaderboard-background', content['colour_content']['iframe-v3-special-leaderboard-background']);
    document.documentElement.style.setProperty('--iframe-v3-trophy-background-gradient', content['colour_content']['iframe-v3-trophy-background-gradient']);
    document.documentElement.style.setProperty('--iframe-v3-restricted-segment-user-modal-cancel-button-color', content['colour_content']['iframe-v3-restricted-segment-user-modal-cancel-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-restricted-segment-user-cancel-button-border-color', content['colour_content']['iframe-v3-restricted-segment-user-cancel-button-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-restricted-segment-user-modal-ok-button', content['colour_content']['iframe-v3-restricted-segment-user-modal-ok-button']);
    document.documentElement.style.setProperty('--iframe-v3-restricted-segment-user-modal-ok-button-text-color', content['colour_content']['iframe-v3-restricted-segment-user-modal-ok-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-header-background', content['colour_content']['iframe-v3-landing-header-background']);
    document.documentElement.style.setProperty('--iframe-v3-landing-header-background-shadow', content['colour_content']['iframe-v3-landing-header-background-shadow']);
    document.documentElement.style.setProperty('--iframe-v3-landing-title-text-color-updated', content['colour_content']['iframe-v3-landing-title-text-color-updated']);
    document.documentElement.style.setProperty('--iframe-v3-prediction-menu-link-background-color', content['colour_content']['iframe-v3-prediction-menu-link-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-prediction-menu-link-active-background-color', content['colour_content']['iframe-v3-prediction-menu-link-active-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-prediction-menu-link-active-text-color', content['colour_content']['iframe-v3-prediction-menu-link-active-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-prediction-menu-link-active-img-filter', content['colour_content']['iframe-v3-prediction-menu-link-active-img-filter']);
    document.documentElement.style.setProperty('--iframe-v3-landing-multi-round-prize-color', content['colour_content']['iframe-v3-landing-multi-round-prize-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-multi-round-bonus-color', content['colour_content']['iframe-v3-landing-multi-round-bonus-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-multi-round-description-color', content['colour_content']['iframe-v3-landing-multi-round-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-closed-round-timer-explanation', content['colour_content']['iframe-v3-closed-round-timer-explanation']);
    document.documentElement.style.setProperty('--iframe-v3-scrollbar-thumb-color', content['colour_content']['iframe-v3-scrollbar-thumb-color']);
    document.documentElement.style.setProperty('--iframe-v3-scrollbar-background-color', content['colour_content']['iframe-v3-scrollbar-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-badge-background', content['colour_content']['iframe-v3-leaderboard-badge-background']);
    document.documentElement.style.setProperty('--iframe-v3-leaderboard-badge-color', content['colour_content']['iframe-v3-leaderboard-badge-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-sign-up-link-color', content['colour_content']['iframe-v3-auth-sign-up-link-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-forgot-password-link-color', content['colour_content']['iframe-v3-auth-forgot-password-link-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-reset-password-confirm-message-color', content['colour_content']['iframe-v3-auth-reset-password-confirm-message-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-button-color', content['colour_content']['iframe-v3-auth-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-button-disabled-color', content['colour_content']['iframe-v3-auth-button-disabled-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-button-text-color', content['colour_content']['iframe-v3-auth-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-form-border-color', content['colour_content']['iframe-v3-auth-form-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-form-background-color', content['colour_content']['iframe-v3-auth-form-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-form-outline-color', content['colour_content']['iframe-v3-auth-form-outline-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-form-error-color', content['colour_content']['iframe-v3-auth-form-error-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-page-header-text-color', content['colour_content']['iframe-v3-auth-page-header-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-back-button-color', content['colour_content']['iframe-v3-auth-back-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-auth-action-link-color', content['colour_content']['iframe-v3-auth-action-link-color']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-box-shadow', content['colour_content']['iframe-v3-my-picks-box-shadow']);
    document.documentElement.style.setProperty('--iframe-v3-statistic-answer-color', content['colour_content']['iframe-v3-statistic-answer-color']);
    document.documentElement.style.setProperty('--iframe-v3-answer-image-background-active', content['colour_content']['iframe-v3-answer-image-background-active']);
    document.documentElement.style.setProperty('--iframe-v3-answer-image-background', content['colour_content']['iframe-v3-answer-image-background']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-default-background', content['colour_content']['iframe-v3-question-progress-default-background']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-default-color', content['colour_content']['iframe-v3-question-progress-default-color']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-selected-color', content['colour_content']['iframe-v3-question-progress-selected-color']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-selected-background', content['colour_content']['iframe-v3-question-progress-selected-background']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-editing-color', content['colour_content']['iframe-v3-question-progress-editing-color']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-editing-background', content['colour_content']['iframe-v3-question-progress-editing-background']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-editing-border', content['colour_content']['iframe-v3-question-progress-editing-border']);
    document.documentElement.style.setProperty('--iframe-v3-question-progress-ellipsis-color', content['colour_content']['iframe-v3-question-progress-ellipsis-color']);
    document.documentElement.style.setProperty('--iframe-v3-landing-no-round-winn-color', content['colour_content']['iframe-v3-landing-no-round-winn-color']);
    document.documentElement.style.setProperty('--iframe-v3-main-question-badge-backgound', content['colour_content']['iframe-v3-main-question-badge-backgound']);
    document.documentElement.style.setProperty('--iframe-v3-main-question-badge-color', content['colour_content']['iframe-v3-main-question-badge-color']);
    document.documentElement.style.setProperty('--iframe-v3-main-question-border-color', content['colour_content']['iframe-v3-main-question-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-question-statistic', content['colour_content']['iframe-v3-question-statistic']);

    if(content['colour_content']['html-background']) {
      document.documentElement.style.setProperty('--html-background', content['colour_content']['html-background']);
    }

    document.documentElement.style.setProperty('--iframe-v3-streak-day-number-icon-bg', content['colour_content']['iframe-v3-streak-day-number-icon-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-number-icon-border', content['colour_content']['iframe-v3-streak-day-number-icon-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-number-text-color', content['colour_content']['iframe-v3-streak-day-number-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-number-icon-active-border', content['colour_content']['iframe-v3-streak-day-number-icon-active-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-number-icon-complete-border', content['colour_content']['iframe-v3-streak-day-number-icon-complete-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-number-icon-complete-bg', content['colour_content']['iframe-v3-streak-day-number-icon-complete-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-star-border', content['colour_content']['iframe-v3-streak-day-star-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-star-border-active', content['colour_content']['iframe-v3-streak-day-star-border-active']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-star-border-complete', content['colour_content']['iframe-v3-streak-day-star-border-complete']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-star-border-results', content['colour_content']['iframe-v3-streak-day-star-border-results']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-inner-text-color', content['colour_content']['iframe-v3-streak-day-inner-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-inner-text-color-result', content['colour_content']['iframe-v3-streak-day-inner-text-color-result']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-info-background', content['colour_content']['iframe-v3-streak-day-info-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-info-left-background', content['colour_content']['iframe-v3-streak-day-info-left-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-info-text', content['colour_content']['iframe-v3-streak-day-info-text']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-info-text-timer', content['colour_content']['iframe-v3-streak-day-info-text-timer']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-bg', content['colour_content']['iframe-v3-streak-day-button-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-border', content['colour_content']['iframe-v3-streak-day-button-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-active-bg', content['colour_content']['iframe-v3-streak-day-button-active-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-active-border', content['colour_content']['iframe-v3-streak-day-button-active-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-locked-border', content['colour_content']['iframe-v3-streak-day-button-locked-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-locked-bg', content['colour_content']['iframe-v3-streak-day-button-locked-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-complete-bg', content['colour_content']['iframe-v3-streak-day-button-complete-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-button-complete-border', content['colour_content']['iframe-v3-streak-day-button-complete-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-menu-active', content['colour_content']['iframe-v3-streak-menu-active']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-info-bg', content['colour_content']['iframe-v3-streak-user-info-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-info-text', content['colour_content']['iframe-v3-streak-user-info-text']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-info-divider-line-color', content['colour_content']['iframe-v3-streak-user-info-divider-line-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-level-bg', content['colour_content']['iframe-v3-streak-user-level-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-points-color', content['colour_content']['iframe-v3-streak-user-points-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-level-text', content['colour_content']['iframe-v3-streak-user-level-text']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-cash-zero-color', content['colour_content']['iframe-v3-streak-user-cash-zero-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-cash-zero-bg', content['colour_content']['iframe-v3-streak-user-cash-zero-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-cash-color', content['colour_content']['iframe-v3-streak-user-cash-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-cash-bg', content['colour_content']['iframe-v3-streak-user-cash-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-first-tooltip-text', content['colour_content']['iframe-v3-streak-first-tooltip-text']);
    document.documentElement.style.setProperty('--iframe-v3-streak-first-tooltip-bg', content['colour_content']['iframe-v3-streak-first-tooltip-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-jackpot-title', content['colour_content']['iframe-v3-streak-jackpot-title']);
    document.documentElement.style.setProperty('--iframe-v3-streak-jackpot-title-border', content['colour_content']['iframe-v3-streak-jackpot-title-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-jackpot-title-bg', content['colour_content']['iframe-v3-streak-jackpot-title-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-jackpot-border', content['colour_content']['iframe-v3-streak-jackpot-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-jackpot-text', content['colour_content']['iframe-v3-streak-jackpot-text']);
    document.documentElement.style.setProperty('--iframe-v3-streak-jackpot-prize', content['colour_content']['iframe-v3-streak-jackpot-prize']);
    document.documentElement.style.setProperty('--iframe-v3-streak-mobile-nav-background', content['colour_content']['iframe-v3-streak-mobile-nav-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-mobile-nav-item-active-background', content['colour_content']['iframe-v3-streak-mobile-nav-item-active-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-mobile-nav-item-active-text-labels-color', content['colour_content']['iframe-v3-streak-mobile-nav-item-active-text-labels-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-desktop-nav-background', content['colour_content']['iframe-v3-streak-desktop-nav-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-desktop-nav-item-text-color', content['colour_content']['iframe-v3-streak-desktop-nav-item-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-desktop-nav-item-active-background', content['colour_content']['iframe-v3-streak-desktop-nav-item-active-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-desktop-nav-item-active-text-color', content['colour_content']['iframe-v3-streak-desktop-nav-item-active-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-desktop-nav-item-active-image-filter', content['colour_content']['iframe-v3-streak-desktop-nav-item-active-image-filter']);
    document.documentElement.style.setProperty('--iframe-v3-streak-main-backgorund-gradient', content['colour_content']['iframe-v3-streak-main-backgorund-gradient']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-streak-subtitle-color', content['colour_content']['iframe-v3-streak-landing-streak-subtitle-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-streak-title-color', content['colour_content']['iframe-v3-streak-landing-streak-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-title-text-shadow', content['colour_content']['iframe-v3-streak-title-text-shadow']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-streak-round-background', content['colour_content']['iframe-v3-streak-landing-streak-round-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-streak-round-header-message', content['colour_content']['iframe-v3-streak-landing-streak-round-header-message']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-streak-round-prize-section-color', content['colour_content']['iframe-v3-streak-landing-streak-round-prize-section-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-streak-explain-color', content['colour_content']['iframe-v3-streak-landing-streak-explain-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-streak-timer-color', content['colour_content']['iframe-v3-streak-landing-streak-timer-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-active-button-text-color', content['colour_content']['iframe-v3-streak-landing-active-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-inactive-button-text-color', content['colour_content']['iframe-v3-streak-landing-inactive-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-active-button-background', content['colour_content']['iframe-v3-streak-landing-active-button-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-view-predictions-button-background', content['colour_content']['iframe-v3-streak-landing-view-predictions-button-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-inactive-button-background', content['colour_content']['iframe-v3-streak-landing-inactive-button-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-divider-color', content['colour_content']['iframe-v3-streak-leaderboard-divider-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-tier-background', content['colour_content']['iframe-v3-streak-leaderboard-tier-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-tier-image-background', content['colour_content']['iframe-v3-streak-leaderboard-tier-image-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-expanded-tooltip-shadow-color', content['colour_content']['iframe-v3-streak-expanded-tooltip-shadow-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-position-text-color', content['colour_content']['iframe-v3-streak-leaderboard-position-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-user-text-color', content['colour_content']['iframe-v3-streak-leaderboard-user-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-items-border-color', content['colour_content']['iframe-v3-streak-leaderboard-items-border-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-tier-conroll-button-color', content['colour_content']['iframe-v3-streak-leaderboard-tier-conroll-button-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-tiers-header-color', content['colour_content']['iframe-v3-streak-leaderboard-tiers-header-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-expanded-tooltip-background', content['colour_content']['iframe-v3-streak-expanded-tooltip-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-expanded-tooltip-text-color', content['colour_content']['iframe-v3-streak-expanded-tooltip-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-page-title', content['colour_content']['iframe-v3-streak-leaderboard-page-title']);
    document.documentElement.style.setProperty('--iframe-v3-streak-base-modal-title-color', content['colour_content']['iframe-v3-streak-base-modal-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-base-modal-description-color', content['colour_content']['iframe-v3-streak-base-modal-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-base-modal-additional-content-color', content['colour_content']['iframe-v3-streak-base-modal-additional-content-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-base-modal-gradient-from', content['colour_content']['iframe-v3-streak-base-modal-gradient-from']);
    document.documentElement.style.setProperty('--iframe-v3-streak-base-modal-gradient-to', content['colour_content']['iframe-v3-streak-base-modal-gradient-to']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-panel-background', content['colour_content']['iframe-v3-streak-info-modal-filter-panel-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-panel-text-color', content['colour_content']['iframe-v3-streak-info-modal-filter-panel-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-panel-border', content['colour_content']['iframe-v3-streak-info-modal-filter-panel-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-item-color', content['colour_content']['iframe-v3-streak-info-modal-filter-item-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-item-selected-color', content['colour_content']['iframe-v3-streak-info-modal-filter-item-selected-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-item-selected-border', content['colour_content']['iframe-v3-streak-info-modal-filter-item-selected-border']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-slider-image-backgorund', content['colour_content']['iframe-v3-streak-info-modal-slider-image-backgorund']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-slider-text-color', content['colour_content']['iframe-v3-streak-info-modal-slider-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-slider-dot-background', content['colour_content']['iframe-v3-streak-info-modal-slider-dot-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-slider-dot-active-background', content['colour_content']['iframe-v3-streak-info-modal-slider-dot-active-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-header-color', content['colour_content']['iframe-v3-streak-info-modal-header-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-header-content', content['colour_content']['iframe-v3-streak-info-modal-header-content']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-content', content['colour_content']['iframe-v3-streak-info-modal-content']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-slider-gradient-left', content['colour_content']['iframe-v3-streak-info-modal-filter-slider-gradient-left']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-modal-filter-slider-gradient-right', content['colour_content']['iframe-v3-streak-info-modal-filter-slider-gradient-right']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-popup-expansion-panel-bottom-color', content['colour_content']['iframe-v3-streak-info-popup-expansion-panel-bottom-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-prompt-light', content['colour_content']['iframe-v3-streak-prompt-light']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-popup-expansion-panel-title', content['colour_content']['iframe-v3-streak-info-popup-expansion-panel-title']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-popup-expansion-panel-body-color', content['colour_content']['iframe-v3-streak-info-popup-expansion-panel-body-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-overlay-panel-background', content['colour_content']['iframe-v3-streak-overlay-panel-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-button-text-color', content['colour_content']['iframe-v3-streak-info-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-info-button-background-color', content['colour_content']['iframe-v3-streak-info-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-history-game-name-color', content['colour_content']['iframe-v3-streak-history-game-name-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-history-page-name-color', content['colour_content']['iframe-v3-streak-history-page-name-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-my-picks-answer-background', content['colour_content']['iframe-v3-streak-my-picks-answer-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-my-picks-question-background', content['colour_content']['iframe-v3-streak-my-picks-question-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-view-results-name-color', content['colour_content']['iframe-v3-streak-view-results-name-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-view-results-subtitle-color', content['colour_content']['iframe-v3-streak-view-results-subtitle-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-view-results-winn-color', content['colour_content']['iframe-v3-streak-view-results-winn-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-view-results-loss-color', content['colour_content']['iframe-v3-streak-view-results-loss-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-view-results-loss-second-line-color', content['colour_content']['iframe-v3-streak-view-results-loss-second-line-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-view-results-button-background-color', content['colour_content']['iframe-v3-streak-view-results-button-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-view-results-button-text-color', content['colour_content']['iframe-v3-streak-view-results-button-text-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-history-button-name', content['colour_content']['iframe-v3-streak-history-button-name']);
    document.documentElement.style.setProperty('--iframe-v3-streak-history-button-bg', content['colour_content']['iframe-v3-streak-history-button-bg']);
    document.documentElement.style.setProperty('--iframe-v3-streak-page-title', content['colour_content']['iframe-v3-streak-page-title']);
    document.documentElement.style.setProperty('--iframe-v3-streak-day-star-border-new-question', content['colour_content']['iframe-v3-streak-day-star-border-new-question']);
    document.documentElement.style.setProperty('--iframe-v3-streak-user-info-secondary-text', content['colour_content']['iframe-v3-streak-user-info-secondary-text']);
    document.documentElement.style.setProperty('--iframe-v3-my-picks-my-picks-block-second-number-color', content['colour_content']['iframe-v3-my-picks-my-picks-block-second-number-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-hiden-round-color', content['colour_content']['iframe-v3-streak-landing-hiden-round-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-hiden-round-background', content['colour_content']['iframe-v3-streak-landing-hiden-round-background']);
    document.documentElement.style.setProperty('--iframe-v3-info-modal-slider-image-backgorund', content['colour_content']['iframe-v3-info-modal-slider-image-backgorund']);
    document.documentElement.style.setProperty('--iframe-v3-auth-form-background', content['colour_content']['iframe-v3-auth-form-background']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-toast-message-background-color', content['colour_content']['iframe-v3-streak-leaderboard-toast-message-background-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-toast-message-title-color', content['colour_content']['iframe-v3-streak-leaderboard-toast-message-title-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-leaderboard-toast-message-description-color', content['colour_content']['iframe-v3-streak-leaderboard-toast-message-description-color']);
    document.documentElement.style.setProperty('--iframe-v3-streak-social-counter-color', content['colour_content']['iframe-v3-streak-social-counter-color']);
    document.documentElement.style.setProperty('--', content['colour_content']['']);
  }

  setUpImage(content) {
    if (!content || !content['image_content'] || !Object.keys(content['image_content'] ).length) return;
    document.documentElement.style.setProperty('--iframe-v3-desktop-background', `url(${content['image_content']['iframe-v3-desktop-background']})`);
    document.documentElement.style.setProperty('--iframe-v3-slider-htumb-icon', `url(${content['image_content']['iframe-v3-slider-htumb-icon']})`);
    document.documentElement.style.setProperty('--iframe-v3-landing-background-coins-mobile', `url(${content['image_content']['iframe-v3-landing-background-coins-mobile']})`);
    document.documentElement.style.setProperty('--iframe-v3-streak-step-done', `url(${content['image_content']['iframe-v3-streak-step-done']})`);
    document.documentElement.style.setProperty('--iframe-v3-streak-step-arrow', `url(${content['image_content']['iframe-v3-streak-step-arrow']})`);
    document.documentElement.style.setProperty('--iframe-v3-streak-step-lock', `url(${content['image_content']['iframe-v3-streak-step-lock']})`);
    document.documentElement.style.setProperty('--iframe-v3-streak-landing-background-flames', `url(${content['image_content']['iframe-v3-streak-landing-background-flames']})`);
    document.documentElement.style.setProperty('--iframe-v3-mobile-background', `url(${content['image_content']['iframe-v3-mobile-background']})`);
    document.documentElement.style.setProperty('--iframe-v3-sign-up-icon', `url(${content['image_content']['iframe-v3-sign-up-icon']})`);
    document.documentElement.style.setProperty('--iframe-v3-auth-back-button', `url(${content['image_content']['iframe-v3-auth-back-button']})`);
    document.documentElement.style.setProperty('--iframe-v3-desktop-additional-background', `url(${content['image_content']['iframe-v3-desktop-additional-background']})`);
    document.documentElement.style.setProperty('--iframe-v3-mobile-additional-background', `url(${content['image_content']['iframe-v3-mobile-additional-background']})`);
    document.documentElement.style.setProperty('--iframe-v3-promo-lights-mobile', `url(${content['image_content']['iframe-v3-promo-lights-mobile']})`);

  }
}
