export enum TenantsEnum {
  napoleon = 'napoleon',
  superbetbrazil = 'superbetbrazil',
  superbetpoland='superbetpoland',
  superbetromania='superbetromania',
  streakbrazil = 'streakbrazil',
  streak = 'streak',
  superbetpromo = 'superbetpromo',
  streakromania = 'streakromania'
}
