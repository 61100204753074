<div class="action-area" [ngClass]="{'vertical-buttons': isVertical}">
  @if (confirmButtonTitle && confirmButtonColor && !singleButton) {
    <button
      (click)="confirmClick.emit()"
      [style.border]="confirmButtonBorderColor ? '1px solid' : ''"
          [ngStyle]="{'color': confirmButtonColor,
          'background': confirmButtonBackgroundColor,
          'border-color': confirmButtonBorderColor}">
      {{ confirmButtonTitle }}
    </button>
  }
  @if (cancelButtonTitle && cancelButtonColor) {
    <button
      (click)="cancelClick.emit()"
      [style.border]="cancelButtonBorderColor ? '1px solid' : ''"
          [ngStyle]="{'color': cancelButtonColor,
          'background': cancelButtonBackgroundColor,
          'border-color': cancelButtonBorderColor}">
      {{ cancelButtonTitle }}
    </button>
  }
</div>
