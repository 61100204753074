import { Injectable } from '@angular/core';
import { map } from "rxjs";

import { SaasSetingsService } from "@services/saas-setings.service";


@Injectable({
  providedIn: 'root'
})
export class PrizeAmountService {

  totalPrizeSumLength= 0;

  constructor(
    private saasSetingsService: SaasSetingsService
  ) { }

  getSinglePrizeAmount(prize, currency, isCashPoints = true, currencyMark = '') {
    return this.saasSetingsService.currencyAfterPrize$.pipe(
      map(currencyAfterPrize => {
        let prizeText = prize;
        if (!isCashPoints) return prizeText;
        const prizeAmount =(currencyAfterPrize ? `${prizeText}${currency}` : `${currency}${prizeText}`) + currencyMark;
        this.totalPrizeSumLength = prizeAmount.length;
        return prizeAmount;
      })
    )
  }
}
