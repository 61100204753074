import { NgClass, NgStyle } from "@angular/common";
import { Component, Input } from "@angular/core";

import { ConfirmationModalTypeEnum } from "src/app/app-common/enums/ConfirmationModalTypeEnum";

import { TenantService } from "../../services/tenant.services";

@Component({
    selector: 'hun-base-modal',
    templateUrl: 'base-modal.component.html',
    styleUrls: ['base-modal.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle]
})
export class BaseModalComponent {

  modalType: ConfirmationModalTypeEnum;

  restrictedAccessModal: boolean;

  @Input() set _modalType (modalType: ConfirmationModalTypeEnum) {
    this.modalType = modalType;
    this.restrictedAccessModal = modalType === ConfirmationModalTypeEnum.bannedUserV3 || modalType === ConfirmationModalTypeEnum.restrictedSegmentUserV3;
  }

  ConfirmationModalTypeEnum = ConfirmationModalTypeEnum;
  constructor(public tenantService: TenantService) {
  }
}
