import { Dialog } from "@angular/cdk/dialog";
import { NgClass, AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { TenantService } from "@common-services/tenant.services";
import { BaseButtonComponent } from "@components/base-button/base-button.component";
import { CmsContentService } from "@services/cms-content.service";
import { PlatformDetectorService } from "@services/platform-detector.service";



@Component({
    selector: 'hun-info-modal-slider',
    templateUrl: './info-modal-slider.component.html',
    styleUrls: ['./info-modal-slider.component.scss'],
    standalone: true,
    imports: [NgClass, BaseButtonComponent, AsyncPipe]
})
export class InfoModalSliderComponent {

  private _slides: { title: string; description: string; imageUrl: string }[] = [];

  @Input()
  set slides(value: { title: string; description: string; imageUrl: string }[]) {
    this._slides = value;
    this.sliderPosition$.next(1);
  }

  get slides(): { title: string; description: string; imageUrl: string }[] {
    return this._slides;
  }

  sliderPosition$ = new BehaviorSubject<number>(1);

  constructor(public cmsContentService: CmsContentService,
              public dialog: Dialog,
              public platformDetectorService: PlatformDetectorService,
              public tenantService: TenantService
              ) {}

  slideBack() {
    this.sliderPosition$.next(this.sliderPosition$.value - 1)
  }

  slideNext() {
    this.sliderPosition$.next(this.sliderPosition$.value + 1)
  }

  close() {
    this.dialog.closeAll();
  }

  createArrayFromNumber(num: number): number[] {
    return Array.from({ length: num }, (_, index) => index + 1);
  }

}
