import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { UriConfig } from "@app/app.config";

@Injectable({
  providedIn: 'root'
})
export class AuthValidationService_v3 {

  noauthUserAvailability$ = new BehaviorSubject<boolean>(false);

  defaultUserAvailability$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {
  }

  checkNoauthUserAvailability(): Observable<{ allowUnauthorizedUsers: boolean }> {
    return this.http.get<{ allowUnauthorizedUsers: boolean }>(`${this.uriConfig.checkUnauth}`).pipe(
      map((value) => {
        this.noauthUserAvailability$.next(value.allowUnauthorizedUsers);
        return value
      })
    )
  }
}
