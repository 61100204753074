import { UserLeaderboardDataModel } from "@models/UserLeaderboardModel";

export class UserLeaderboard {
	private static defaultUserLeaderboardData: UserLeaderboardDataModel = {
		position: 0,
		points: 0,
		prize: 0,
		tierName: '',
		tierColor: '',
		pointsToClosestTier: 0,
		positionToClosestTier: 0,
		closestTierName: '',
		streakDay: 0,
		level: 0,
		correctAnswersAmount: 0
	};

	static generateDefaultUserLeaderboardData(): UserLeaderboardDataModel {
		return { ...this.defaultUserLeaderboardData };
	}

	static generateUserLeaderboardData(data: UserLeaderboardDataModel | null): UserLeaderboardDataModel {
		return {
			...this.defaultUserLeaderboardData,
			...data,
		};
	}
}
