import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTabNav, MatTabNavPanel } from '@angular/material/tabs';

import { InfoModalTabsItemDetail } from "@models/InfoModalTabsItemDetail";

@Component({
    selector: 'hun-info-modal-filter',
    templateUrl: './info-modal-filter.component.html',
    styleUrls: ['./info-modal-filter.component.scss'],
    standalone: true,
    imports: [MatTabNav, NgClass, MatTabNavPanel]
})
export class InfoModalFilterComponent {

  @Input() filterOptions: InfoModalTabsItemDetail[];

  @Output() selectedFilterOption = new EventEmitter<string>()
}
