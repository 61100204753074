<div class="modal">
  <div class="title">
    <div>{{ data.header }}</div>
    <img
      class="close-btn"
      (click)="closeModal()"
      [src]="data.image">
  </div>
  @if (data.content) {
    <div class="description content">
      <div [innerHtml]="data.content"></div>
    </div>
  } @else {
    @if (cmsContentService.cmsContent$ | async; as cmsContent) {
      <div class="description">
        <div
          class="prize-description-wrapper prize-space-wrapper"
          [innerHTML]="getDescription(cmsContent['text_content']) | async">
        </div>
        <div class="amount-description">
          {{ cmsContent['text_content']['iframe-v3-prize-modal-amount-description'] }}
        </div>
        <ul>
          @for (prize of data.body.prizeList; track prize) {
            @if (prize.assignment === 'SPLIT' && cmsContent['text_content']['iframe-v3-prize-modal-shared-prize-description']) {
              <li
                style="list-style-type: none;">
                {{ cmsContent['text_content']['iframe-v3-prize-modal-shared-prize-description'] }}
              </li>
            }
            <li>
              <span class="prize-amount-wrapper prize-space-wrapper">
                {{
                prizeAmountService.getSinglePrizeAmount(
                prize.prize,
                cmsContent['text_content']['iframe-v3-currency'],
                prize.type === PrizeTypeEnum.CASH_POINTS
                ) | async
                }}
              </span>–
              {{ prize.correctCount }}/{{ data.body.totalCount }}
            </li>
          }
        </ul>
      </div>
    }
  }
  <div class="close-prize-modal-button">
    <hun-two-buttons-confirmation-modal
      [cancelButtonTitle]="data.labelCancel"
      [cancelButtonColor]="data.colorCancel"
      [cancelButtonBackgroundColor]="data.cancelButtonBackgroundColor"
      [singleButton]="data.singleButton"
      (cancelClick)="closeModal()"
      >
    </hun-two-buttons-confirmation-modal>
  </div>
</div>

