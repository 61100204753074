@if ((sliderPosition$ | async); as sliderPosition) {
  @if ((cmsContentService.cmsContent$ | async); as cmsContent) {
    <div class="slider"
      [ngClass]="{'safari': (platformDetectorService.isSafari$ | async) && (platformDetectorService.isIOS$ | async)}"
      >
      <div class="slider-wrapper">
        <div class="image-wrapper">
          <img [src]="slides[sliderPosition - 1]?.imageUrl">
        </div>
        <div class="slider-title">
          {{slides[sliderPosition - 1].title}}
        </div>
        <div class="slider-description" [innerHTML]="slides[sliderPosition - 1].description"></div>
      </div>
      <div class="slider-dots">
        @for (position of createArrayFromNumber(slides.length); track position) {
          <div class="dot" [ngClass]="{'active': position === sliderPosition}"></div>
        }
      </div>
      @if ((sliderPosition$ | async); as sliderPosition) {
        <div class="buttons-container">
          @if (tenantService.isStreak$ | async) {
            @if (sliderPosition !== 1) {
              <hun-base-button
                class="game-button"
                [disabled]="false"
                [bgColor]="cmsContent['colour_content']['iframe-v3-streak-info-popup-slider-back-button-background']"
                [color]="cmsContent['colour_content']['iframe-v3-streak-info-popup-slider-back-button-text-color']"
                [borderColor]="cmsContent['colour_content']['iframe-v3-streak-info-popup-slider-back-button-border-color']"
                (handleClick)="slideBack()"
                >
                {{cmsContent['text_content']['iframe-v3-streak-info-popup-slider-back-button']}}
              </hun-base-button>
            }
            @if (sliderPosition !== slides.length) {
              <hun-base-button
                [ngClass]="{'one-button': sliderPosition !== slides.length && !(sliderPosition === slides.length) && !(sliderPosition !== 1) }"
                class="game-button"
                [disabled]="false"
                [bgColor]="cmsContent['colour_content']['iframe-v3-streak-info-popup-slider-next-button-background']"
                [color]="cmsContent['colour_content']['iframe-v3-streak-info-popup-slider-next-button-text-color']"
                (handleClick)="slideNext()"
                >
                {{cmsContent['text_content']['iframe-v3-streak-info-popup-slider-next-button']}}
              </hun-base-button>
            }
            @if (sliderPosition === slides.length) {
              <hun-base-button
                [ngClass]="{'one-button': sliderPosition === slides.length && !(sliderPosition !== slides.length) && !(sliderPosition !== 1)}"
                class="game-button"
                [disabled]="false"
                [bgColor]="cmsContent['colour_content']['iframe-v3-streak-info-popup-slider-next-button-background']"
                [color]="cmsContent['colour_content']['iframe-v3-streak-info-popup-slider-next-button-text-color']"
                (handleClick)="close()"
                >
                {{cmsContent['text_content']['iframe-v3-streak-info-popup-slider-close-button-text']}}
              </hun-base-button>
            }
          } @else {
            @if (sliderPosition !== 1) {
              <hun-base-button
                class="game-button"
                [disabled]="false"
                [bgColor]="cmsContent['colour_content']['iframe-v3-info-popup-slider-back-button-background']"
                [color]="cmsContent['colour_content']['iframe-v3-info-popup-slider-back-button-text-color']"
                [borderColor]="cmsContent['colour_content']['iframe-v3-info-popup-slider-back-button-border-color']"
                (handleClick)="slideBack()"
                >
                {{cmsContent['text_content']['iframe-v3-info-popup-slider-back-button']}}
              </hun-base-button>
            }
            @if (sliderPosition !== slides.length) {
              <hun-base-button
                [ngClass]="{'one-button': sliderPosition !== slides.length && !(sliderPosition === slides.length) && !(sliderPosition !== 1) }"
                class="game-button"
                [disabled]="false"
                [bgColor]="cmsContent['colour_content']['iframe-v3-info-popup-slider-next-button-background']"
                [color]="cmsContent['colour_content']['iframe-v3-info-popup-slider-next-button-text-color']"
                (handleClick)="slideNext()"
                >
                {{cmsContent['text_content']['iframe-v3-info-popup-slider-next-button']}}
              </hun-base-button>
            }
            @if (sliderPosition === slides.length) {
              <hun-base-button
                [ngClass]="{'one-button': sliderPosition === slides.length && !(sliderPosition !== slides.length) && !(sliderPosition !== 1)}"
                class="game-button"
                [disabled]="false"
                [bgColor]="cmsContent['colour_content']['iframe-v3-info-popup-slider-next-button-background']"
                [color]="cmsContent['colour_content']['iframe-v3-info-popup-slider-next-button-text-color']"
                (handleClick)="close()"
                >
                {{cmsContent['text_content']['iframe-v3-info-popup-slider-close-button-text']}}
              </hun-base-button>
            }
          }
        </div>
      }
    </div>
  }
}

