import { isNull, isUndefined } from "lodash";
import moment from "moment";

import { GameStatusEnum } from "@enums/GameStatusEnum";
import { HistoricalAnswerStatusEnum } from "@enums/HistoricalAnswerStatusEnum";
import { StreakModel } from "@models/StreakModel";

export class StreakFactory {
	static generateStreak(response: any): StreakModel[] {
		const streakDays: StreakModel[] = [];
		const levelsAmount = response.levelsAmount;
		const nextRoundOpensAt = response.nextRoundOpensAt && moment(response.nextRoundOpensAt).isAfter(new Date()) ? response.nextRoundOpensAt : moment(response.closeDate).isAfter(new Date()) ? response.closeDate : null;
		const seenNextRound = response.seenNextRound;
		const prize = response.prize;
		const current = response.currentRoundData;
    const streakLevel = response.streakLevel;
    const isSocialStatisticsEnabled = response.isSocialStatisticsEnabled;
		const answerStatusForCurrentRound: { status: string, roundId: number } = response.answersStatus.find(res => {
			return current && res.roundId === current.id
		});
		const showStreakLvlResetMessage = response.viewedStreakLvlResetMessage === false;
		const skippedQuestionsForResetting = response.skippedQuestionsForResetting || 0;
		const viewedResults = response.viewedPreviousRoundResult;
		const lastAnswer = response.answersStatus[response.answersStatus.length - 1]

		const shouldShowStreakBeforeReset = viewedResults === false && !isNull(response.streakLevelBeforeResetting)
			&& response.streakLevelBeforeResetting !== 0
			&& lastAnswer.roundId === current.id;

		const isIncorrectAnswer = answerStatusForCurrentRound?.status === HistoricalAnswerStatusEnum.Incorrect;
		const isCorrectAnswer = current && current.status === GameStatusEnum.COMPLETED && answerStatusForCurrentRound
			&& answerStatusForCurrentRound.status === HistoricalAnswerStatusEnum.Correct;
		const showStreakLvlReset = viewedResults === true && !isNull(response.streakLevelBeforeResetting) && showStreakLvlResetMessage;

		const userLevel = (shouldShowStreakBeforeReset && isIncorrectAnswer) || showStreakLvlReset
			? response.streakLevelBeforeResetting
			: shouldShowStreakBeforeReset && (!isUndefined(answerStatusForCurrentRound) && lastAnswer.roundId !== current.id)
				? response.streakLevelBeforeResetting - 1
				: !showStreakLvlResetMessage && isCorrectAnswer ? response.streakLevel - 1 : response.streakLevel;

// TODO refactor after demo
		for (let i = 0; i < levelsAmount; i++) {
			if (current) {
				const isCurrentCompletedAndResulted = current.status === GameStatusEnum.COMPLETED && answerStatusForCurrentRound && !isNull(answerStatusForCurrentRound.status) && i === userLevel
				streakDays.push({
					prize,
					seenNextRound,
					index: i + 1,
					isActive: userLevel === i && current.status !== GameStatusEnum.COMPLETED && !viewedResults
						|| isCurrentCompletedAndResulted && !viewedResults,
					isComplete: (i < userLevel && current.status === GameStatusEnum.CLOSED)
						|| (i < userLevel - 1 && (current.status === GameStatusEnum.COMPLETED))
						|| isCurrentCompletedAndResulted || i < userLevel,
					isOpened: userLevel === i && current && current.status === GameStatusEnum.OPENED,
					openedIn: userLevel === i && current && current.status === GameStatusEnum.PENDING ? current.openDate : null,
					closeIn: userLevel === i && current && current.status === GameStatusEnum.OPENED ? current.closeDate : !current ? response.closeDate : null,
					isClosed: userLevel === i && current && current.status === GameStatusEnum.CLOSED,
					isJackpot: i === levelsAmount - 1,
					answersReviewed: !!answerStatusForCurrentRound && !isNull(answerStatusForCurrentRound.status) && isCurrentCompletedAndResulted
					|| i === userLevel && current.status !== GameStatusEnum.COMPLETED && current.hasPlayed
						? false
						: i < userLevel,
					hasPlayed: current && current.hasPlayed || null,
					nextRoundOpensAt,
					roundId: (userLevel === i && !!current && current.status !== GameStatusEnum.COMPLETED) || (!!current && current.status === GameStatusEnum.COMPLETED) ? current.id : null,
					firstDayStreak: i === 0 && !current.hasPlayed && !response.answersStatus.length && current.status === GameStatusEnum.OPENED,
					resulted: isCurrentCompletedAndResulted && answerStatusForCurrentRound && answerStatusForCurrentRound.status !== GameStatusEnum.PENDING && current.hasPlayed,
					resultStatus: response.answersStatus.find(res => {
						if (res.roundId === current.id) return res
					})?.status,
					streakId: response.id,
					name: userLevel === i && !!current && current.status !== GameStatusEnum.COMPLETED || userLevel - 1 === i && current.status === GameStatusEnum.COMPLETED ? current && current.name : null,
					showStreakLvlResetMessage,
					skippedQuestionsForResetting,
          isSocialStatisticsEnabled,
          streakLevel
				})
			} else if (response.correctAnswersNeeded === userLevel) {
				streakDays.push({
					prize,
					seenNextRound,
					index: i + 1,
					isActive: false,
					isComplete: response.correctAnswersNeeded === userLevel,
					isOpened: i === levelsAmount - 1,
					openedIn: null,
					closeIn: null,
					isClosed: false,
					isJackpot: i === levelsAmount - 1,
					answersReviewed: response.correctAnswersNeeded === userLevel,
					hasPlayed: null,
					nextRoundOpensAt,
					roundId: null,
					firstDayStreak: null,
					resulted: false,
					resultStatus: null,
					name: '',
					streakId: response.id,
					showStreakLvlResetMessage,
					skippedQuestionsForResetting,
          isSocialStatisticsEnabled,
          streakLevel
				})
			} else {
				streakDays.push({
					prize,
					seenNextRound,
					index: i + 1,
					isActive: i === userLevel,
					isComplete: i < userLevel,
					isOpened: i === levelsAmount,
					openedIn: nextRoundOpensAt ? nextRoundOpensAt : null,
					closeIn: null,
					isClosed: false,
					isJackpot: i === levelsAmount - 1,
					answersReviewed: i < userLevel,
					hasPlayed: null,
					nextRoundOpensAt,
					roundId: null,
					firstDayStreak: null,
					resulted: false,
					resultStatus: null,
					name: '',
					streakId: response.id,
					showStreakLvlResetMessage,
					skippedQuestionsForResetting,
          isSocialStatisticsEnabled,
          streakLevel
				})
			}
		}
		return streakDays;
	}
}
