import { Injectable } from '@angular/core';

import { environment } from "../environments/environment";

@Injectable()
export class UriConfig {
  baseApiUrl = environment.apiUrl;

  frameSingin = `${this.baseApiUrl}auth/external/signin`;

  login = `${this.baseApiUrl}auth/signin`;

  auth = `${this.baseApiUrl}auth`;

  rounds = `${this.baseApiUrl}rounds`;

  questions = `${this.baseApiUrl}questions`;

  answers = `${this.baseApiUrl}answers`;

  users = `${this.baseApiUrl}users`;

  files = `${this.baseApiUrl}files`;

  leaderboard = `${this.baseApiUrl}leaderboards/b2c`;

  settings = `${this.baseApiUrl}settings`;

  checkModifying = `${this.baseApiUrl}check-modifying`;

  cms = `${this.baseApiUrl}cms`;

  checkUnauth = `${this.baseApiUrl}check-unauth`;

  sse = `${this.baseApiUrl}sse`;

  infoModalTabs = `${this.baseApiUrl}info-popup-tabs/b2c`;

  validTicket = `${this.baseApiUrl}superbet-api/validate-ticket`;

  upsellBanners = `${this.baseApiUrl}upsell-banners/b2c`

}
