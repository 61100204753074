@if ((cmsContentService.cmsContent$ | async); as cmsContent) {
  <div class="header-container">
    <span>
      <ng-content></ng-content>
    </span>
    @if ((tenantService.isNoTenenat$ | async) || (isDesktopService.isDesktop$ | async)) {
      <img
        class="close-icon"
        alt="close-icon"
        (click)="closeModal()"
        [src]="(tenantService.isStreak$ | async) ? cmsContent?.image_content['iframe-v3-streak-cross-icon'] : cmsContent?.image_content['iframe-v3-cross-icon']">
    }
  </div>
}
