<nav mat-tab-nav-bar mat-stretch-tabs class="filter-panel" [tabPanel]="tabPanel">
  @for (option of filterOptions; track option) {
    <span
      class="filter-item"
      [ngClass]="{'selected': option.isSelected}"
      (click)="selectedFilterOption.emit(option.label)">
      {{option.label}}
    </span>
  }
</nav>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>


