import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { map } from "rxjs";

import { AuthEnum } from "@enums/AuthEnum";
import { AuthService } from "@services/auth.service";
import { CmsContentService } from "@services/cms-content.service";
import { SnackBarService } from "@services/snack-bar.service";

export const AllowAuthNextStep: CanActivateFn = () => {
	const router = inject(Router);
	const authService = inject(AuthService);
	const cmsContentService = inject(CmsContentService);
	const snackBarService = inject(SnackBarService);
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');
	const confirmationToken = urlParams.get('confirmationToken');
  let url = `auth/${AuthEnum.SIGN_IN}`;
  if (!token && !confirmationToken) {
    router.navigate([url], { replaceUrl: true });
    return true;
  }
	if (confirmationToken) {
		return authService.confirmSignUp(confirmationToken)
			.pipe(
				map(data => {
					authService.processUser(data);
					router.navigate(['frame'], { replaceUrl: true });
					return true
				})
			)
	}

	authService.restorePasswordToken$.next(token);
	return authService.validateResetPassword({ token }).pipe(
    map(({ isValid }) => {
      if (isValid) {
        url = `auth/${AuthEnum.SET_PASSWORD}`
      } else {
        snackBarService.showSnackBar(cmsContentService.cmsContent$.value.text_content['iframe-v3-auth-reset-token-expired'])
      }
      router.navigate([url], { replaceUrl: true });
      return true;
    })
  );
};
