import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';

import { UriConfig } from "@app/app.config";

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  submitAnswersScope(answers) {
    return this.http.post(`${this.uriConfig.answers}/submit`, answers);
  }
}
