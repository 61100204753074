import { Component } from '@angular/core';

@Component({
    selector: 'hun-main-modal',
    templateUrl: './main-modal.component.html',
    styleUrls: ['./main-modal.component.scss'],
    standalone: true,
})
export class MainModalComponent {
  constructor() { }
}
