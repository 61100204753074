import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import {
  MatBottomSheet,
  MAT_BOTTOM_SHEET_DATA,
  MAT_BOTTOM_SHEET_DEFAULT_OPTIONS
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  Router,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig
} from '@angular/router';
import * as Sentry from "@sentry/angular";
import player from "lottie-web";
import { provideLottieOptions } from 'ngx-lottie';

import { routes } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { UriConfig } from './app/app.config';
import { OnlyFrameAccessGuard } from './app/core/guards/only-frame-access.guard';
import { ErrorHandlerInterceptor } from './app/core/interseptors/error-handler.interceptor';
import { JwtTokenInterceptor } from './app/core/interseptors/jwt-token.interceptor';
import { PlatformLoadingInterceptor } from './app/core/interseptors/platform-loading.interceptor';
import { SetLocaleInterceptor } from './app/core/interseptors/set-locale.interceptor';
import { AppInitializeService } from './app/core/services/app-initialize.service';
import { SnackBarService } from './app/core/services/snack-bar.service';
import { initializeAppFactory } from './app/initializeAppFactory';
import { environment } from './environments/environment';
Sentry.init({
  dsn: environment.sentryDsn,
  ignoreErrors: [
    "User with this nickname already exists",
    "Unauthorized",
    "Forbidden",
    "Cannot read properties of null (reading 'version')",
    "Failed to load CMS content",
  ],
  environment: environment.name,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, MatDialogModule, MatSnackBarModule, MatButtonModule),
    UriConfig,
    SnackBarService,
    OnlyFrameAccessGuard,
    provideRouter(routes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload'
      }),
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetLocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformLoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    //uncomment if you need test error
    // { provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorEmulationInterceptor,
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitializeService],
      multi: true
    },
    { provide: MatBottomSheet },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { panelClass: 'frame-overlay-panel' } },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
  .catch(err => {
    console.error(err)
  });
