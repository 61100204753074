<button
  [disabled]="disabled"
  [ngStyle]="{
      'background': getBackground(),
      'color':getTextColor(),
      'border': borderStyle,
      'font-weight': fontWeight,
      'font-family': fontFamily,
      'font-size': fontSize,
      'height': height,
      'padding': padding,
      'line-height': lineHeight,
      'border-color': borderColor}"
  [ngClass]="{'no-border': noBorder, 'with-border': !!borderColor}"
  (click)="handleClick.emit()">
  <ng-content></ng-content>
</button>
