import { NgClass, AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';

import { IsDesktopService } from "@common-services/is-desktop.services";
import { TenantService } from "@common-services/tenant.services";
import { CmsContentService } from "@services/cms-content.service";
import { MaintenanceControlService } from "@services/maintenance-control.service";


@Component({
    selector: 'hun-lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.scss'],
    standalone: true,
    imports: [NgClass, AsyncPipe]
})
export class LockComponent {

  constructor(
    public cmsContentService: CmsContentService,
    public isDesktopService: IsDesktopService,
    public tenantService: TenantService,
    public maintenanceControlService: MaintenanceControlService
  ) {}

  isCocpitDataReceived(config) {
    if (!config || !Object.keys(config).length) return false;
    return !(!config.colour_content || !config.text_content || !config.image_content);

  }

}
