import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { UriConfig } from "@app/app.config";
import { InfoModalTabModel } from "@models/InfoModalTabModel";

@Injectable({ providedIn: "root" })
export class PrizeModalService {
	constructor(
		private http: HttpClient,
		private uriConfig: UriConfig,
	) {
	}

	getRoundPrizePopup(id: number) {
		return this.http.get<InfoModalTabModel>(this.uriConfig.infoModalTabs + `/prize-popups/${id.toString()}`)
	}
}
