import { Component, Input } from '@angular/core';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';

@Component({
    selector: 'hun-items-expansion-pannel',
    templateUrl: './items-expansion-pannel.component.html',
    styleUrls: ['./items-expansion-pannel.component.scss'],
    standalone: true,
    imports: [MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle]
})
export class ItemsExpansionPannelComponent {

  @Input() faqContent: { title: string, content: string }[];

}
