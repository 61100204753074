@if (faqContent) {
  <div class="content">
    @for (contentItem of faqContent; track contentItem) {
      <mat-accordion class="panel-item" >
        <mat-expansion-panel >
          <!--      onclick="this.blur()" need to prevent adding focused class, becouse it overlap bottom sheet-->
          <mat-expansion-panel-header onclick="this.blur()">
            <mat-panel-title>
              {{contentItem.title}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [innerHTML]="contentItem.content"></div>
        </mat-expansion-panel>
      </mat-accordion>
    }
  </div>
}
