import { inject } from "@angular/core";
import { CanActivateFn, Router } from '@angular/router';

import { TenantService } from "@common-services/tenant.services";
import { environment } from "src/environments/environment";

export const AllowManualSignInGuard: CanActivateFn = () => {
	const router = inject(Router);
  const tenantService = inject(TenantService);
  const isTestTenant = window.location.href.includes('test');

	if (environment.production && !isTestTenant && !tenantService.isSuperbetPromo$.value) {
		router.navigate(['frame'], { replaceUrl: true });
		return false;
	}

	return true;
};
