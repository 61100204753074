import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ConfirmationModalTypeEnum } from '@enums/ConfirmationModalTypeEnum';
import { SignInPopupButtonsActionEnum } from "@enums/SignInPopupButtonsActionEnum";
import { ConfirmationModalDetails } from "@models/ConfirmationModalDetails";

import { ModalWindowService } from "../../services/modal-window.service";
import { BaseModalComponent } from '../base-modal/base-modal.component';
import {
  TwoButtonsConfirmationModalComponent
} from '../two-buttons-confirmation-modal/two-buttons-confirmation-modal.component';

@Component({
    selector: 'hun-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    standalone: true,
    imports: [BaseModalComponent, TwoButtonsConfirmationModalComponent, NgClass]
})
export class ConfirmationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalDetails,
    private modalWindowService: ModalWindowService
  ) {
  }

  okButtonClick() {
    switch (this.data.modalType) {
      case ConfirmationModalTypeEnum.signInV3:
        this.dialogRef.close(SignInPopupButtonsActionEnum.login);
        break
      case ConfirmationModalTypeEnum.restrictedSegmentUserV3:
        window.open(this.data.okButtonLink);
        break;
      default:
        this.dialogRef.close(true);
    }
    this.modalWindowService.isAnyModalOpened.next(false);
  }

  cancelButtonClick() {
    switch (this.data.modalType) {
      case ConfirmationModalTypeEnum.signInV3:
        this.dialogRef.close(SignInPopupButtonsActionEnum.register);
        break
      default:
        this.dialogRef.close(false);
    }
    this.modalWindowService.isAnyModalOpened.next(false);

  }

}
