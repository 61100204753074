import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import {
  BehaviorSubject,
  delay,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  throwError
} from "rxjs";
import { catchError, filter, map, takeUntil } from "rxjs/operators";

import { UriConfig } from "@app/app.config";
import { SettingAvailabilityEnum } from "@enums/SettingAvailabilityEnum";
import { SettingsConfigDetail } from "@models/SettingsConfigDetail";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceControlService {

  isMaintenance$ = new BehaviorSubject(false);

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private uriConfig: UriConfig,
    private router: Router,
    private authService: AuthService,
    protected http: HttpClient,
  ) {
  }

  private eventSource: EventSource | undefined;

  connect(): Observable<MessageEvent> {
    if (!this.eventSource) {
      this.eventSource = new EventSource(this.uriConfig.sse + '/maintenance-mode');
    }

    return new Observable((observer) => {
      this.eventSource.addEventListener('message', (event: MessageEvent) => {
        observer.next(event);
      });

      this.eventSource.addEventListener('error', (error) => {
        this.reconnect()
        observer.complete();
      });
    });
  }

  reconnect() {
    this.closeConnection().pipe(
      delay(1000),
      filter(() => navigator.onLine),
      switchMap(() => this.getSettingsList()),
      switchMap((settings) => {
        const isLock = settings?.find(setting => setting.key === 'maintenance_mode')?.value === 'true';
        this.isMaintenance$.next(isLock);
        return this.connect();
      }),
      tap((event: MessageEvent) => {
        const isLock = JSON.parse(event.data).isMaintenanceModeEnabled;
        this.isMaintenance$.next(isLock);
      }),
      catchError((error) => {
        return throwError(error);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe()
  }

  closeConnection() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = undefined;
    }
    return of(true);
  }

  getSettingsList(): Observable<SettingsConfigDetail[]> {
    let params = new HttpParams();
    params = params.append('availability', SettingAvailabilityEnum.B2C);
    params = params.append('availability', SettingAvailabilityEnum.BOTH);

    return this.http.get<SettingsConfigDetail[]>(this.uriConfig.settings + '/b2c', { params });
  }

  subscribeMaintenanceCheck() {
    this.connect().pipe(
      tap((event: MessageEvent) => {
        const isLock = JSON.parse(event.data).isMaintenanceModeEnabled;
        this.isMaintenance$.next(isLock);
      }),
      catchError((error) => {
        return throwError(error);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe()
  }

  maintenanceControl() {
    this.isMaintenance$.pipe(
      map(isMaintenance => {
        if (isMaintenance) {
          this.router.navigate([`lock`]);
        }
        return isMaintenance;
      }),
      filter(isMaintenance => !isMaintenance),
      map(() => this.authService.initUser()),
      tap(() => {
        if (window.location.href.includes('lock')) {
          this.router.navigate([`frame`]);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  maintenanceDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
