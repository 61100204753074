import { NgStyle, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'hun-base-button',
    templateUrl: './base-button.component.html',
    styleUrls: ['./base-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, NgClass]
})
export class BaseButtonComponent {

  @Input() bgColor: string;

  @Input() color: string;

  @Input() lineHeight: string;

  @Input() borderStyle: string;

  @Input() borderColor: string;

  @Input() disabledBgColor: string;

  @Input() disabledColor: string;

  @Input() noBorder: boolean;

  @Input() disabled: boolean;

  @Input() fontWeight: number;

  @Input() fontFamily: 'Inter' | 'Inter-Bold' | 'Inter-Medium';

  @Input() fontSize = '18px';

  @Input() height = 'unset';

  @Input() padding = '13px';

  @Output() handleClick = new EventEmitter();

  getBackground() {
    return this.disabled && this.disabledBgColor ? this.disabledBgColor : this.bgColor;
  }

  getTextColor() {
    return this.disabled && this.disabledColor ? this.disabledColor : this.color;
  }
}
