import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";

import { FRAME_DATA } from "../frame.config";

@Injectable({
  providedIn: 'root'
})
export class IsDesktopService {

  isDesktop$: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.isDesktop$ = this.breakpointObserver.observe([
      `(max-width: ${FRAME_DATA.LIMIT_BOTTOM}px)`
    ]).pipe(
      map((result: BreakpointState) => !result.matches)
    );
  }

}
