import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { LanguageService } from "@services/language.service";


@Injectable()
export class SetLocaleInterceptor implements HttpInterceptor {

	localeRequiredRequest = [
		'/cms',
		'/answers',
		'/history',
		'/default',
		'/info-popup-tabs',
		'/upsell-banners',
		'/leaderboards',
		'/questions',
	]

	constructor(
		private languageService: LanguageService,
	) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const containApi = this.localeRequiredRequest.some(localeRequest => request.url.includes(localeRequest));
    if (!containApi) {
			return next.handle(request);
		}

		return this.languageService.currentLocale$.pipe(
			switchMap((locale: string) => {
        const existingLocale = request.params.get('locale');
        if (existingLocale && existingLocale === locale) {
          return next.handle(request);
        }
				const modifiedReq = locale ?
					request.clone({
						params: request.params.append('locale', locale),
					}) :
					request;
				return next.handle(modifiedReq);
			})
		);
	}
}
