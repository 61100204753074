@if ((cmsContentService.cmsContent$ | async); as cmsContentConfig) {
  <hun-main-modal
    class="info-popup"
    [ngClass]="{'mobile-info-popup': !(isDesktopService.isDesktop$ | async)}"
    >
    <hun-modal-header header>
      @if (selectedOption$ | async) {
        {{(selectedOption$ | async).label}}
      }
    </hun-modal-header>
    <div body>
      @if ((menuFilterOptions$ | async); as filterOptions) {
        <hun-info-modal-filter
          class="info-filter info-gradient"
      [ngClass]="{
      'info-gradient-right': !(isDesktopService.isDesktop$ | async) && (showRightGradient$ | async),
      'info-gradient-left': (showLeftGradient$ | async) && !(isDesktopService.isDesktop$ | async)}"
          [filterOptions]="filterOptions"
          (selectedFilterOption)="onSelectFilterOption($event, filterOptions)">
        </hun-info-modal-filter>
      }
      @if ((selectedOption$ | async); as selectedOption) {
        <div class="info-content-container" [ngClass]="{faq: selectedOption.isFAQ, slider: selectedOption.isSlider}">
          @if (!selectedOption.isSlider) {
            <div class="info-content-header">{{selectedOption.expandedLabel}}</div>
            <ng-container>
              @if (!selectedOption.isFAQ) {
                <div class="content">
                  <div class="list">
                    <div [innerHTML]="selectedOption.value"></div>
                  </div>
                </div>
              }
              @if (selectedOption.isFAQ) {
                <div class="content">
                  <hun-items-expansion-pannel [faqContent]="selectedOption.value.items ? selectedOption.value.items : selectedOption.value"></hun-items-expansion-pannel>
                </div>
              }
            </ng-container>
          } @else {
            <hun-info-modal-slider [slides]="selectedOption.value.slides"></hun-info-modal-slider>
          }
          <ng-template #slider>
            <hun-info-modal-slider [slides]="selectedOption.value.slides"></hun-info-modal-slider>
          </ng-template>
        </div>
      }
    </div>
  </hun-main-modal>
}
