import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from "rxjs";

import { UriConfig } from "@app/app.config";
import { UserDetailsModel } from "@models/UserDetail";
import { UserStatistic } from 'src/app/app-common/models/UserStatistic';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  isRestrictedUser$ = new BehaviorSubject<boolean>(false);

  public userDetails$ = new BehaviorSubject<UserDetailsModel>(null);

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getCurrentUser(): Observable<UserDetailsModel> {
    return this.http.get<UserDetailsModel>(this.uriConfig.users).pipe(
      tap((user) => this.setUserDetails(user))
    );
  }

  saveUserData(body): Observable<UserDetailsModel>{
    return this.http.patch<UserDetailsModel>(this.uriConfig.users, body).pipe(
      tap((user) => this.setUserDetails(user))
    );
  }

  getUserStatistic(): Observable<UserStatistic> {
    return this.http.get<UserStatistic>(`${this.uriConfig.users}/statistic`);
  }

  setUserDetails (user) {
    this.userDetails$.next(user);
  }
}
