import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { isNull, replace } from "lodash";
import { Observable, of, switchMap, take } from "rxjs";

import { UriConfig } from "@app/app.config";
import { ModalWindowService } from "@common-services/modal-window.service";
import { TenantService } from "@common-services/tenant.services";
import { PrizeSplitEnum } from "@enums/PrizeSplitEnum";
import { BreakdownDataItem, InfoModalTabModel, InfoPopupBreakdownData } from "@models/InfoModalTabModel";
import { UserService } from "@services/user.service";


@Injectable({
  providedIn: 'root'
})
export class InfoPopupService {

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private userService: UserService,
    private modalWindowService: ModalWindowService,
    private tenantService: TenantService,
  ) {
  }

  getInfoModalTabs(): Observable<InfoModalTabModel[]> {
    return this.http.get<InfoModalTabModel[]>(this.uriConfig.infoModalTabs);
  }

  onboardUser() {
    return this.userService.getCurrentUser()
      .pipe(
        take(1),
        switchMap((user) => {
          if (!user.hasPassedOnboarding || !user.hasSeenInfoTab) {
            this.modalWindowService.openInfoModal();
            if (!user.hasPassedOnboarding) return this.userService.saveUserData({ hasPassedOnboarding: true })
            if (!user.hasSeenInfoTab) return this.userService.saveUserData({ hasSeenInfoTab: true })
            return of(true)
          } else {
            return of(true);
          }
        })
      )
  }

  getInfoPopupData(): Observable<InfoPopupBreakdownData> {
    let query = this.tenantService.isSuperbetRomania$.value ? 4 : this.tenantService.isNapoleon$.value ? 2 : 3
    return this.http.get<InfoPopupBreakdownData>(this.uriConfig.infoModalTabs + `/breakdown-winnings?minValue=${query}`)
  }

  generateInfoPopupContent(template: string, data: InfoPopupBreakdownData) {
    let finalContent = template;

    // Replace basic placeholders in the template
    if (template.includes("{roundName}") && data.roundName) {
      finalContent = finalContent.replace(/{roundName}/g, data.roundName);
    }
    if (template.includes("{roundId}") && data.roundId) {
      finalContent = finalContent.replace(/{roundId}/g, data.roundId.toString());
    }
    if (template.includes("{totalWinningCash}") && !isNull(data.totalWinningCash)) {
      finalContent = finalContent.replace(/{totalWinningCash}/g, data.totalWinningCash.toString());
    }
    if (template.includes("{totalQuestionAmount}") && !isNull(data.totalQuestionAmount)) {
      finalContent = finalContent.replace(/{totalQuestionAmount}/g, data.totalQuestionAmount.toString());
    }
    if (template.includes("{totalWinningUsers}") && !isNull(data.totalWinningUsers)) {
      finalContent = finalContent.replace(/{totalWinningUsers}/g, data.totalWinningUsers.toString());
    }

    const hasDefaultPrizeText = template.includes("{defaultPrizeText}");
    const hasSplitPrizeText = template.includes("{splitPrizeText}");

    let defaultTemplate = '';
    let splitTemplate = '';

    if (hasDefaultPrizeText) {
      // Extract the DEFAULT prize template section
      const defaultStart = template.indexOf("{defaultPrizeText}");
      const defaultEnd = template.indexOf("{defaultPrizeTextEnd}") + "{defaultPrizeTextEnd}"?.length;
      defaultTemplate = template.slice(defaultStart, defaultEnd)
        .replace("{defaultPrizeText}", "")
        .replace("{defaultPrizeTextEnd}", "");
      if (defaultTemplate.match(/<li>(.*?)<\/li>/g)) {
        defaultTemplate = defaultTemplate.match(/<li>(.*?)<\/li>/g)[0]
      }
      finalContent = finalContent.replace(/{defaultPrizeText}[\s\S]*?{defaultPrizeTextEnd}/, '');
    }

    if (hasSplitPrizeText) {
      // Extract the SPLIT prize template section
      const splitStart = template.indexOf("{splitPrizeText}");
      const splitEnd = template.indexOf("{splitPrizeTextEnd}") + "{splitPrizeTextEnd}"?.length;
      splitTemplate = template.slice(splitStart, splitEnd)
        .replace("{splitPrizeText}", "")
        .replace("{splitPrizeTextEnd}", "");
      if (splitTemplate.match(/<li>(.*?)<\/li>/g)) {
        splitTemplate = splitTemplate.match(/<li>(.*?)<\/li>/g)[0]
      }
      finalContent = finalContent.replace(/{splitPrizeText}[\s\S]*?{splitPrizeTextEnd}/, '')
    } else if (defaultTemplate) {
      splitTemplate = defaultTemplate
    }

    // If both identifiers are missing, use the generic <ul>...</ul> as the default and split template
    if (!hasDefaultPrizeText || !hasSplitPrizeText) {
      if (template.match(/<ul>(.*?)<\/ul>/g)?.length) {
        const listStart = template.indexOf('<ul>');
        const listEnd = template.indexOf('</ul>') + 5; // Include </ul> closing tag
        const listTemplate = template.slice(listStart, listEnd);
        const listItemTemplate = listTemplate.match(/<li>(.*?)<\/li>/g)?.length ? listTemplate.match(/<li>(.*?)<\/li>/g)[0] : listTemplate;
        if (!hasDefaultPrizeText) defaultTemplate = listItemTemplate;
        if (!hasSplitPrizeText) splitTemplate = listItemTemplate;
        finalContent = finalContent.replace(/<ul>(.*?)<\/ul>/g, '');
      } else if (template.match(/<p>([^<]*?\{answeredCorrectly\}[^<]*?)<\/p>/)?.length) {
        const listTemplate = template.match(/<p>([^<]*?\{answeredCorrectly\}[^<]*?)<\/p>/)[0];
        if (!hasDefaultPrizeText) defaultTemplate = listTemplate;
        if (!hasSplitPrizeText) splitTemplate = listTemplate;
      }
      finalContent = finalContent.replace(/<p>([^<]*?\{answeredCorrectly\}[^<]*?)<\/p>/, '');
    }


    // Generate the breakdown list dynamically based on breakdownData
    let breakdownList = '';
    if (template.includes('{desc}')) {
      data.breakdownData.sort((a, b) => b.answeredCorrectly - a.answeredCorrectly);
      finalContent = finalContent.replace(/{desc}/g, '');
    } else {
      data.breakdownData.sort((a, b) => a.answeredCorrectly - b.answeredCorrectly);
    }

    data.breakdownData.forEach((item: BreakdownDataItem) => {
      // Choose the correct list item template based on prizeType
      let listItem = item.assignment === PrizeSplitEnum.SPLIT ? splitTemplate : defaultTemplate;

      // Replace placeholders in the selected list item template with item data
      if (listItem.includes('{answeredCorrectly}') && !isNull(item.answeredCorrectly)) {
        listItem = listItem.replace(/{answeredCorrectly}/g, item.answeredCorrectly.toString());
      }
      if (listItem.includes('{totalQuestionAmount}') && !isNull(data.totalQuestionAmount)) {
        listItem = listItem.replace(/{totalQuestionAmount}/g, data.totalQuestionAmount.toString());
      }
      if (listItem.includes('{usersAmount}') && !isNull(item.usersAmount)) {
        listItem = listItem.replace(/{usersAmount}/g, item.usersAmount.toString());
      } else if (!item.usersAmount && this.tenantService.isSuperbetPoland$.value) {
        listItem = ''
      }
      if (listItem.includes('{reward}') && !isNull(item.reward)) {
        let reward;
        if (item.assignment === PrizeSplitEnum.SPLIT) {
          if (item.usersAmount) {
            reward =  Math.round((item.reward / item.usersAmount) * 100) / 100;
          } else {
            reward = item.reward;
          }
        } else if (item.assignment === PrizeSplitEnum.EACH) {
          reward = item.reward;
        }

        if (item.usersAmount > 0) {
          listItem = listItem.replace(/{reward}/g, reward.toString());
        } else {
          const regex = /\s*,?\s*\(?\s*{reward}\s*\)?\s*[^<\)]*\)?/g;
          listItem = listItem.replace(regex, '');
        }
      } else if (listItem.includes('{reward}') && isNull(item.reward)) {
        listItem = ''
      }
      breakdownList += listItem;
    });

    finalContent = finalContent + `<ul>${breakdownList}</ul>`;

    return finalContent;
  }
}
