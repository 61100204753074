import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MaintenanceControlService } from "@services/maintenance-control.service";




@Injectable({
  providedIn: 'root'
})
export class FrameAccessGuard  {

  constructor(
    private router: Router,
    private maintenanceControlService: MaintenanceControlService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.maintenanceControlService.isMaintenance$
    .pipe(
      map((isMaintenance) => {
        if (isMaintenance && !state.url.includes(`lock`) ) {
          return this.router.parseUrl(`lock`);
        }
        if ((state.url.includes(`/lock`) && !isMaintenance)) {
          return this.router.parseUrl('/frame');
        }
        return true;
      }),
    )
  }
}
