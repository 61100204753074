import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';

import { IsDesktopService } from "@common-services/is-desktop.services";
import { ModalWindowService } from "@common-services/modal-window.service";
import { TenantService } from "@common-services/tenant.services";
import { CmsContentService } from "@services/cms-content.service";



@Component({
    selector: 'hun-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    standalone: true,
    imports: [AsyncPipe]
})
export class ModalHeaderComponent {

  constructor(
    public cmsContentService: CmsContentService,
    public modalWindowService: ModalWindowService,
    public isDesktopService: IsDesktopService,
    public tenantService: TenantService
  ) { }


  closeModal() {
    this.modalWindowService.closeModal();
  }
}
